.about-overview {
  padding: 2rem 4rem;
  color: #fff;
  background-color: #312f44;
  position: relative;
  display: flex;
  justify-content: space-around;
}
.about-overview > div:first-child {
  width: 56%;
  margin-left: -5rem;
  transform: translateX(-9rem);
  opacity: 0;
}
.about-overview > div:first-child.visible {
  transform: translateX(0);
  opacity: 1;
  transition: all 0.5s ease;
  transition-delay: 0.3s;
}
.about-overview img {
  width: 300px;
  height: 400px;
  border-radius: 10px;
  transform: translateX(8rem);
  opacity: 0;
}
.about-overview img.visible {
  transform: translateX(0);
  opacity: 1;
  transition: all 0.5s ease;
  transition-delay: 0.3s;
}
.about-overview h1 {
  font-size: 3.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.about-overview p {
  font-size: 1.06rem;
  line-height: 1.5;
  margin-bottom: 2.5rem;
}
.about-overview .learn-more {
  font-size: 1.1rem;
  text-decoration: underline;
  text-underline-offset: 0.25rem;
  color: #2cc26d;
  cursor: pointer;
}

@media (max-width: 900px) {
  .about-overview {
    flex-flow: column-reverse;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  .about-overview * {
    margin: 0;
    padding: 0;
  }
  .about-overview > div:first-child {
    width: 100%;
    margin: 0;
  }
  .about-overview img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: top 26% left 0;
  }
}
@media (max-width: 500px) {
  .about-overview h1 {
    font-size: 2.5rem;
  }
  .about-overview p {
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
  }
}
