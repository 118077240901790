.hero{
    /* width: 100vw; */
    height: 90vh;
    position: relative;
}

.programs .hero-cover{
    /* position: relative; */
    width: 100%;
    height: 100%;
    z-index: 10;
   /* background-image: linear-gradient(to right, rgba(0,0,0,0.85), 
   transparent); */
   background-color: rgba(0, 0, 0, 0.65);
   display: flex;
   flex-flow: column;
   gap: 1rem;
   justify-content: center;
   padding-inline: 7rem;
   color: white;  
}

.hero-cover .left{
    width: 60px;
    height: 60px;
    fill: lightgray;
    position: absolute;
    top: 40%;
    left: 1rem;
}

.hero-cover .right{
    position: absolute;
    width: 60px;
    height: 60px;
    fill: lightgray;
    top: 40%;
    right: 2rem;
}

.hero-cover h1{
    font-size: 4rem;
    width: 50%;
    animation: txtSlideIn 0.3s linear forwards;
}

.hero-cover p{
    font-size: 0.9rem;
    width: 40%;
    color: lightgray;
    line-height: 2.3;
    transform: translateX(10rem);
    opacity: 0;
    animation: txtSlideIn 0.3s linear forwards;
    animation-delay: 0.1s;
}

.hero img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -10;
    animation: imageFadeIn 0.3s linear forwards;
}
@keyframes imageFadeIn{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
@keyframes txtSlideIn{
    from{
        transform: translateX(10rem);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        opacity: 1;
    }
}

@media (max-width:800px) {
    .hero-cover h1{
        font-size: 3.5rem;
    }
    .hero-cover p{
        width: 70%;
    }
    .hero{
        height: 80vh;
    }
}

@media (max-width:700px) {
    .hero{
        height: 70vh;
    }

    .hero img{
        object-position: top 0px left -80px;
    }
}
@media (max-width:600px) {
    .hero-cover h1{
        font-size: 3rem;
    }
    .hero-cover p{
        line-height: 1.5;
        width: 85%;
    }
    .programs .hero-cover{
        padding-inline: 4rem;
    }
    .hero-cover .right{
        width: 50px;
        height: 50px;
    }
    .hero-cover .left{
        width: 50px;
        height: 50px;
    }
    .hero img{
        object-position: top 0px left -220px;
    }
}    

@media (max-width:500px) {
    .hero{
        height: 65vh;
    }
    .hero-cover h1{
        font-size: 2.35rem;
    }
    .hero-cover p{
        line-height: 1.2;
        width: 95%;
        font-size: 0.9rem;
    }
    .hero-cover .right{
        right: 1rem;
    }

}