.key-areas{
    padding: 2rem 6rem;
    color: #312F44;
}
.key-areas .lists-container{
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    transform: translateY(7rem);
    opacity: 0;
    transition: all 0.5s ease;
}
.key-areas .lists-container.visible {
    transform: translateY(0);
    opacity: 1;
    transition: all 0.5s ease;
}
.key-areas > h1{
    font-size: 2.5rem;
    margin-bottom: 1rem;
    transform: translateX(-6rem);
    opacity: 0;
}
.key-areas > h1.visible{
    transform: translateX(0);
    opacity: 1;
    transition: all 0.5s ease;
    transition-delay: 0.2s;
}
.key-areas > hr{
    width: 15%;
    height: 0.5rem;
    border: none;
    background-color: #2d784e;
    margin-bottom: 1.1rem;
}
.key-areas .lists-container div{
    width: 30%;
    height: 12rem;
    background-color: #f7f7f7;
    text-align: center;
    padding: 2rem 1rem;
    border-radius: 6px;
    display: flex;
    flex-flow: column;
    gap: 2rem;
    align-items: center;
}
.key-areas .lists-container div svg{
    width: 80px;
    height: 80px;
    fill: #2d784e;
}
.key-areas .lists-container h2{
    font-size: 1.2rem;
}
@media(max-width:1000px){
    .key-areas .lists-container h2{
        font-size: 1rem;
    }
}
@media(max-width:880px){
    .key-areas{
        padding-inline: 2rem;
    }
    .key-areas>h1{
        font-size: 1.6rem;
    }
}
@media(max-width:650px){
    .key-areas .lists-container div{
        width: 45%;
    }
}
@media(max-width:450px){
    .key-areas{
        padding-inline: 1rem;
    }
    .key-areas>h1{
        font-size: 1.2rem;
    }
    .key-areas > hr{
        width: 15%;
    }
    .key-areas .lists-container div{
        height: 10rem;
        gap: 1rem;
        padding: 1rem;
    }
    .key-areas .lists-container h2{
        font-size: 0.85rem;
    }
    .key-areas .lists-container div svg{
        width: 50px;
        height: 50px;
    }
}