.program-events header > h1{
    font-size: 2.2rem;
}

.program-events{
    width: 100%;
    /* height: 75vh; */
    background-color: #312F44;
    color: white;
    padding: 2rem 2rem 5rem 5rem;
    display: flex;
    flex-flow: column;
    gap: 2rem;
}

.program-events header{
    width: 100%;
    /* height: 25%; */
}

.program-events header hr{
    background-color: #2d784e;
    width: 5%;
    height: 7px;
    border: 0;
}

.program-events main{
    width: 100%;
    /* height: 75%; */
    display: flex;
    gap: 1rem;
}

main .program-events-container{
    width: 25%;
    height: 20rem;
    display: flex;
    gap: 4rem;
    margin-left: 3rem;
}

.program-events-container .program-event{
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
    padding: 0.7rem;
    border-radius: 10px;
}

.program-event .eventTitle{
    color: black;
    padding: 1rem;
    font-size: 1rem;
}

.program-event .eventImg{
    width: 100%;
    height: 60%;
    border-radius: inherit;
}
.program-event .eventImg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}
.program-event .eventButton{
    text-align: center;
}

.program-event .eventButton button{
    border: 0;
    color: #2d784e;
    font-size: 1rem;
}


main .more-events{
    width: 80%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    text-align: center;
}

.more-events h1{
    font-size: 2.65rem;
}

.more-events button{
    padding: 0.6rem;
    border-radius: 5px;
    background-color: #2d784e;
    color: white;
    border: 0;
}

.more-events button:hover{
    background-color: whitesmoke;
    color: #312F44;
}


@media (max-width:1200px) {
    main .program-events-container{
        width: 30%;
    }
    main .more-events{
        width: 70%;
    }
    .program-event .eventTitle{
        font-size: 0.9rem;
    }   
    .program-event .eventButton button{
        font-size: 1rem;
    }
}

@media (max-width:1000px){
    .more-events h1{
        font-size: 1.8rem;
    }
    .more-events button{
     font-size: 0.85rem;
     padding: 0.35rem;
    }

    .program-events{
        padding-block: 3rem;
        padding-inline: 3.5rem;
    }
    main .program-events-container{
        width: 30%;
    }

}

@media (max-width:850px) {
    .program-events{
        padding-inline: 3rem;
        /* height: 80vh; */
    }
    main .program-events-container{
        width: 40%;
        margin-left: 1rem;
    }

    main .more-events{
        width: 70%;
    }
}

@media (max-width:650px){
    .program-events main{
        flex-flow: column;
        align-items: center;
        gap: 4rem;
    }   
    .program-events{
        height: 75vh;
        padding-inline: 2rem;
        gap: 2rem;
    }
    main .program-events-container{
        width: 65%;
    }

    .program-event .eventTitle{
        font-size: 1rem;
    }
    main .more-events{
        gap: 0.9rem;
        width: 100%;
        text-align: center;
    }

}

@media (max-width:505px){
    .program-events main{
        gap: 2.5rem;
    }

    .program-events{
        gap: 2rem;
        padding-inline: 1rem;
        height: 75vh;
    }
    .more-events h1{
        font-size: 1.5rem;
    }
    main .program-events-container{
        width: 75%;
    }
}

@media (max-width:400px) {
    main .programs-events-container{
        width: 85%;
    }
}