.what-we-do {
  padding: 2rem 0rem;
  padding-bottom: 0;
  background-color: #286442;
  color: white;
  position: relative;
  z-index: 0;
}
.what-we-do .jobs-txt {
  padding: 0rem 6rem;
}
.what-we-do .jobs-txt > h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  transform: translateX(-8rem);
  opacity: 0;
}
.what-we-do .jobs-txt > h1.visible{
  transition: all 0.5s ease;
  opacity: 1;
  transform: translateX(0);
}
.what-we-do .jobs-txt > p {
  font-size: 1.2rem;
  line-height: 1.5;
  transform: translateY(8rem);
  opacity: 0;
}
.what-we-do .jobs-txt > p.visible{
  opacity: 1;
  transform: translateY(0);
  transition: all 0.5s ease;
}
.what-we-do .jobs-txt hr{
  height: 0.5rem;
  margin-bottom: 1.5rem;
  border: 0;
  background-color: #fff;
  width: 10%;
}
.what-we-do .jobs-container {
  display: flex;
  gap: 2rem;
  margin-top: 3rem;
}
.jobs-btn {
  position: absolute;
  top: 50%;
  border: 0;
  z-index: 1100;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  cursor: pointer;
  background-color: transparent;
}
.jobs-btn svg {
  width: 100%;
  height: 100%;
  fill: #8b979a;
}
.jobs-btn.left {
  left: 1.5%;
}
.jobs-btn.right {
  right: 1.5%;
}

@media (max-width: 900px) {
  .what-we-do .jobs-txt {
    padding: 2rem;
  }
}
@media(max-width:780px){
  .jobs-btn{
    top: 70%;
  }
}
@media(max-width:500px){
  .what-we-do .jobs-txt {
    padding-inline: 1rem;
  }
  .what-we-do .jobs-txt > h1{
    font-size: 1.5rem;
  }
  .what-we-do .jobs-txt > p{
    font-size: 0.9rem;
  }
  .jobs-btn{
    top: 88%;
  }
  .what-we-do .jobs-txt hr{
    width: 23%;
  }
}