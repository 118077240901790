.team-list{
    padding: 1rem 6rem;
    background-color: #f7f7f7;
    color: #312F44;
    display: flex;
    gap: 2rem;
    width: 100%;
    flex-wrap: wrap;
}
.team{
    width: 23%;
    background-color: #fff;
    padding: 1rem 1.5rem;
    border-radius: 7px;
    transform: translateY(8rem);
    opacity: 0;
}
.team.visible{
    transform: translateY(0);
    opacity: 1;
    transition: all 0.5s ease;
}
.team img{
    width: 100%;
    height: 19rem;
    border-radius: 8px;
    object-fit: cover;
    margin-bottom: 1rem;
}
.team h2{
    font-size: 1.3rem;
    margin-bottom: 1rem;
}
.team h4{
    font-size: 0.85rem;
    font-weight: 500;
    line-height: 1.5;
}
.team button{
    border: none;
    background-color: #2d784e;
    color: #fff;
    font: 0.9rem;
    padding: 0.3rem 0.5rem;
    border-radius: 6px;
    margin-top: 1rem;
    cursor: pointer;
}

@media(max-width:1400px){
    .team{
        width: 31%;
    }
}
@media(max-width:1110px){
    .team{
        width: 30%;
    }
}
@media(max-width:950px){
    .team-list{
        padding-inline: 2rem;
    }
}
@media(max-width:750px){
    .team{
        width: 45%;
    }
}
@media(max-width:590px){
    .team-list{
        padding-inline: 1rem;
    }
    .team{
        width: 100%;
    }
    .team img{
        height: 23rem;
    }
}