.resources-list {
  padding: 1rem 6rem;
  color: #312f44;
  min-height: 70vh;
  background-color: #f7f7f7;
}
.resource {
  display: flex;
  padding: 1rem 3rem;
  background-color: #fff;
  border-radius: 5px;
  justify-content: space-between;
  gap: 5rem;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow: hidden;
  margin-bottom: 1rem;
  transform: translateY(7rem);
  opacity: 0;
}
.resource.visible{
  transform: translateY(0);
  opacity: 1;
  transition: all 0.5s ease;
}
.resource .img-loader {
  width: 80vw;
  height: 30vh;
  position: absolute;
  top: -15rem;
  z-index: 1000;
  right: 0;
  background-color: #fff;
}
.resource .img-loader .wheel {
  width: 200px;
  height: 200px;
  border: 15px solid #f7f7f7;
  border-top: 15px solid gray;
  border-radius: 50%;
  margin-left: 5rem;
  margin-top: 0rem;
  animation: loader-spin 1s linear forwards infinite;
}
.resource div {
  width: 60%;
}
.resource div:nth-child(2) {
  width: 40%;
  position: relative;
}
.resource h2 {
  font-size: 1.3rem;
  margin-bottom: 3rem;
  line-height: 1.5;
}
.resource a {
  color: #2d784e;
  padding: 0.3rem 0.5rem;
  text-underline-offset: 0.2rem;
  font-size: 1.1rem;
  transition: all 0.1s ease;
}
.resource a:hover {
  transition: all 0.1s ease;
  color: #61d694;
}
.resource img {
  width: 100%;
  height: 100%;
}

@keyframes loader-spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 950px) {
  .resources-list {
    padding-inline: 2rem;
  }
}
@media (max-width: 750px) {
  .resource {
    flex-flow: column-reverse;
    text-align: center;
    padding-inline: 2rem;
  }
  .resource h2 {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    text-align: left;
  }
  .resource div {
    width: 100%;
  }
  .resource div:nth-child(2) {
    width: 100%;
  }
  .resource a {
    font-size: 0.9rem;
  }
}
@media (max-width: 450px) {
  .resources-list {
    padding-inline: 1rem;
  }
  .resource {
    padding-inline: 1rem;
  }
}
