.resources-header {
  padding: 1rem 6rem;
  background-color: #f7f7f7;
  color: #312f44;
}
.resources-header hr {
  background-color: #2d784e;
  height: 0.5rem;
  width: 10%;
}
.resources-header h1{
    font-size: 2.5rem;
    margin-bottom: 1rem;
    transform: translateX(-8rem);
    opacity: 0;
}
.resources-header h1.visible{
    opacity: 1;
    transform: translateX(0);
    transition: all 0.5s ease;
}

@media(max-width:650px){
    .resources-header{
        padding-inline: 2rem;
    }
    .resources-header h1{
        font-size: 1.7rem;
    }
    .resources-header hr{
        width: 15%;
    }
}
@media(max-width:450px){
    .resources-header{
        padding-inline: 1rem;
    }
    .resources-header h1{
        font-size: 1.3rem;
    }
    .resources-header hr{
        width: 20%;
    }
}