.activities{
    padding-block: 5rem;
    background-color: whitesmoke;
}

.activities h1{
    margin-left: 7rem;
}

.activities hr{
    margin-left: 7rem;
    width: 5%;
    height: 7px;
    background-color: #2d784e;
    margin-top: 0.5rem;
}

.activities-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 7rem;
    gap: 5rem 5rem;

}

.activity{ 
    background-color: white;
    color: black;
    width: 20%;
    /* height: 20rem; */
    border-radius: 10px;
    transition: 0.2s;
    animation: scroll-slide 0.5s ease-in-out;
    display: flex;
    flex-flow: column;
    transform: translateY(7rem);
    opacity: 0;
}
.activity.visible{
    transition: all 0.5s ease;
    opacity: 1;
    transform: translateY(0);
}

.activity-animation{
    background-color: whitesmoke;
    color: black;
    width: 20%;
    height: 20rem;
    border-radius: 15px;
    transition: 0.2s;
    animation: scroll-slide 0.5s ease-in-out;

}

.activity:hover{
    transform: translateY(-5px);
    box-shadow: 1px 1px 5px lightgray;
    transition: 0.2s;
}

.activity .see-less .img{
    width: 100%;
    height: 12rem;
    padding: 1rem;
}

.activity .see-less .img img{
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0 0;
    object-fit: cover;
}

.activity .see-less .title{
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem;
    font-size: 0.8rem;
    color: #312F44;
    text-align: center;
    /* word-spacing: 0.2rem; */
}
.activity .see-less .button{
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.activity .see-less .button button{
    border: 0;
    height: 100%;
    padding: 0.8rem 1.2rem;
    font-size: 1em;
    color: #2d784e;
    background-color: transparent;
    border-radius: 15px;
    cursor: pointer;
}
.activity .see-less{
    animation: fadeIn 0.5s linear forwards;
}
.activity .see-more{
    height: 100%;
    border-radius: 10px;
    padding: 2rem;
    font-size: 0.9rem;
    background-color: #2d784e;
    color: white;
    animation: fadeIn 0.5s linear forwards;
}
.activity .see-more .detail{
    width: 100%;
    height: 90%;
    border: 10x solid white;
}

.activity .see-more ul{
    display: flex;
    flex-flow: column;
    gap: 1rem;
}
.activity .see-more .button{
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.activity .see-more .button button{
    border: 0;
    padding: 0.8rem 1.2rem;
    font-size: 1em;
    color: white;
    background-color: transparent;
    border-radius: 15px;
    cursor: pointer;
}

@keyframes fadeIn{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@media (max-width:1500px) {
    .activity{
        width: 26.5%;
    }
}
@media (max-width:1010px) {
    .activities-container{
        padding: 2rem 5rem;
        gap: 5rem 3rem;
    }
    .activity{
        width: 28%;
    }
}

@media (max-width:900px) {
    .activity{
        height: 16rem;
    }
    .activities-container{
        padding-inline: 2rem 5rem;
        gap: 2rem 3rem;
    }
    .activity .see-less .img{
        height: 10rem;
    }
    .activity .see-less .title{
        font-size: 0.6rem;
    }
    .activity .see-less .button{
        font-size: 0.8rem;
    }
    .activities h1{
        margin-left: 5rem;
        font-size: 1.8rem;
    }

    .activity .see-more{
        padding-inline: 2rem;
        padding-block: 1rem;
        font-size: 0.75rem;
    }
    .activity .see-more ul{
        gap: 0.5rem;
    }
}

@media (max-width:800px) {
    .activities-container{
        padding-inline: 3rem;
    }
}
@media (max-width:700px) {
    .activity{
        width: 43%;
    }
    .activities-container{
        padding: 2rem 3rem;
        gap: 3rem 3rem;
    }
}

@media (max-width:550px){
    .activities-container{
        gap: 3rem 2rem;
        padding-inline: 2rem;
    }
    .activity{
        width: 46%;
    }
}

@media (max-width:500px) {
    .activity{
        width: 80%;
    }
    .activities-container{
       justify-content: center;
    }
    .activity .see-less .img{
        padding: 0.5rem;
    }
    .activities h1{
        margin-left: 2rem;
    }
    .activities hr{
        margin-left: 10%;
        width: 3rem;
    }
}
@media (max-width:400px) {
    .activity{
        width: 80%;
    }
    .activity .see-less .img{
        padding: 0.5rem;
    }
    .activities{
        display: flex;
        flex-flow: column;
        padding: 2rem 1rem;
    }
    .activities-container{
       justify-content: center;
       padding: 2rem 1rem;
       gap: 1.5rem;
    }
    .activities h1{
        justify-self: start;
    }

}
