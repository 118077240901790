@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow-x: hidden; */
}
body{
    font-family: "Lato", sans-serif;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}
body > div{
  overflow-x: hidden;
}
::-webkit-scrollbar{
  width: 15px;
  height: 100%;
  display: none;
}
::-webkit-scrollbar-thumb{
  background-color: #2d784e;
  cursor: pointer;
  border: 3px solid darkgray ;
}
::-webkit-scrollbar-track{
  background-color: darkgray;
}
/* Colors used  */

/* #2d784e  */
/* #312F44  */