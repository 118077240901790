.map-container iframe{
    border-radius: 6px;
    border: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media(max-width:600px){
    .map-container{
        width: 100%;
        text-align: center;
        min-height: 40vh;
    }
    .map-container iframe{
        height: 40vh;
    }
}