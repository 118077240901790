.our-partners{
    padding: 2rem 4rem;
    display: flex;
    opacity: 0;
    transform: translateY(2rem);
    filter: blur(5px);
}
.our-partners.visible{
    transform: translateY(0);
    opacity: 1;
    filter: blur(0);
    transition: all 0.4s ease;
}
.partners-heading{
    font-size: 3rem;
    margin: 2rem 4rem;
    color: #312F44;

}
.our-partners h1{
    margin-bottom: 13rem;
}
.our-partners ul{
    display: flex;
    gap: 1.5rem;
    margin-inline: 1.5rem;
    animation: autoscroll 20s linear forwards infinite;
}
.our-partners img{
    width: 320px;
    height: 250px;
}
.our-partners li{
    list-style-type: none;
}
@keyframes autoscroll{
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(-105%);
    }
}

@media(max-width:900px){
    .partners-heading{
        font-size: 2rem;
        margin: 2rem;
    }
    .our-partners img{
        height: 180px;
        width: 180px;
    }
}
@media(max-width:500px){
    .partners-heading{
        font-size: 1.7rem;
        margin: 2rem 1rem;
    }
    .our-partners img{
        height: 120px;
        width: 120px;
    }
}