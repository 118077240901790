.events-header{
    padding: 2rem 6rem;
    color: #312F44;
    background-color: #f7f7f7;
}
.events-header h1{
    font-size: 2.7rem;
    margin-bottom: 0.5rem;
    transform: translateX(-8rem);
    opacity: 0;
}
.events-header h1.visible{
    transition: all 0.5s ease;
    transform: translateX(0);
    opacity: 1;
}
.events-header p{
    margin-bottom: 1rem;
    font-size: 1.2rem;
}
.events-header hr{
    height: 0.5rem;
    border: none;
    background-color: #2d784e;
    width: 10%;
    margin-bottom: 1rem;
}
@media(max-width: 750px){
    .events-header{
        padding: 2rem;
    }
    .events-header h1{
        font-size: 2rem;
    }
    .events-header p{
        font-size: 1rem;
    }
    .events-header hr{
        width: 15%;
    }
}
@media(max-width:450px){
    .events-header{
        padding: 1rem;
    }
    .events-header h1{
        font-size: 1.5rem;
    }
    .events-header p{
        font-size: 0.9rem;
    }
    .events-header hr{
        width: 20%;
    }
}